import { ReactSelectOption } from '@/components/ui/ReactMultiSelect'
import { OptionType } from '@/components/ui/ReactSelect'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'sonner'

export const getOffersList = createAsyncThunk<
  any,
  { per_page?: number; page: number; order?: string },
  { rejectValue: { error: any } }
>('getOffersList', async ({ per_page, page, order }, thunkAPI) => {
  try {
    const response = await fetch(
      `${process.env.API_URL}/sales_offers?per_page=${per_page ? per_page : 8}&page=${page}&order=${order ?? 'desc'}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const responseData = await response.json()
    return responseData
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error })
  }
})

export const addOffer = createAsyncThunk<
  FormData,
  any,
  { rejectValue: object }
>('addOffer', async (formData: FormData, { rejectWithValue }) => {
  try {
    const response = await fetch(`${process.env.API_URL}/sales_offers`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    })
    const responseData = await response.json()
    if (!response.ok) {
      throw new Error(responseData.errors)
    }
    toast.success('Offer Created Successfully')
    return responseData
  } catch (error) {
    return rejectWithValue({ error: error })
  }
})

export const deleteOffer = createAsyncThunk<
  any,
  string,
  { rejectValue: { error: any } }
>('deleteOffer', async (offerId: string, { rejectWithValue }) => {
  try {
    const response = await fetch(
      `${process.env.API_URL}/sales_offers/${offerId}`,
      {
        method: 'DELETE',
        credentials: 'include',
      }
    )
    const responseData = await response.json()
    toast.success('Offer Deleted Successfully')
    return responseData
  } catch (error) {
    return rejectWithValue({ error: error })
  }
})

export const editOffer = createAsyncThunk<
  any,
  { offerId: string | number | undefined; data: FormData },
  { rejectValue: { error: any } }
>('updateoffer', async ({ offerId, data }, thunkAPI) => {
  try {
    const response = await fetch(
      `${process.env.API_URL}/sales_offers/${offerId}`,
      {
        method: 'PATCH',
        body: data,
        credentials: 'include',
      }
    )

    if (!response.ok) {
      throw new Error('Failed to update profile')
    }

    const responseData = await response.json()
    toast.success('Offer Updated Successfully')
    return responseData
  } catch (error) {
    console.error('Error while updating profile:', error)
    toast.error('Error While Updating')
    return thunkAPI.rejectWithValue({ error: 'Error while updating profile' })
  }
})

export const getOfferDetails = createAsyncThunk<
  any,
  number,
  { rejectValue: { error: string } }
>('getOfferDetails', async (offerId: number, thunkAPI) => {
  try {
    const response = await fetch(
      `${process.env.API_URL}/sales_offers/${offerId}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    if (!response.ok) {
      throw new Error('Failed to fetch offer details')
    }

    const responseData = await response.json()
    return responseData
  } catch (error) {
    console.error('Error while fetching offer details:', error)
    return thunkAPI.rejectWithValue({
      error: 'Error while fetching offer details',
    })
  }
})

export const getRelatedOffersList = createAsyncThunk<
  any,
  number,
  { rejectValue: { error: string } }
>('getRelatedOffersList', async (offerId: number, thunkAPI) => {
  try {
    const response = await fetch(
      `${process.env.API_URL}/sales_offers/${offerId}/related`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    if (!response.ok) {
      throw new Error('Failed to fetch offer details')
    }

    const responseData = await response.json()
    return responseData
  } catch (error) {
    console.error('Error while fetching offer details:', error)
    return thunkAPI.rejectWithValue({
      error: 'Error while fetching offer details',
    })
  }
})

export const getSuggestedOffersList = createAsyncThunk<
  any,
  void,
  { rejectValue: { error: string } }
>('getSuggestedOffersList', async (_, thunkAPI) => {
  try {
    const response = await fetch(
      `${process.env.API_URL}/sales_offers/suggestions`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    if (!response.ok) {
      throw new Error('Failed to fetch suggested offer')
    }

    const responseData = await response.json()
    return responseData
  } catch (error) {
    console.error('Error while fetching suggested offer:', error)
    return thunkAPI.rejectWithValue({
      error: 'Error while fetching suggested offer',
    })
  }
})

export const findProductType = (name: string, productFamilyList: any) => {
  const productType = productFamilyList.find((item: any) => item.name === name)
  return productType ? productType.id : null
}

export async function fetchBlobAndConvertToFiles(urls: string[]) {
  try {
    const files = await Promise.all(
      urls.map(async (url) => {
        const response = await fetch(url)
        const blobData = await response.blob()
        const filename = url.substring(url.lastIndexOf('/') + 1)
        return new File([blobData], filename, { type: blobData.type })
      })
    )
    return files
  } catch (error) {
    console.error('Error fetching blobs and converting to files:', error)
    toast.error('Error fetching images')
    return []
  }
}

export const validateOffer = (
  product: { id: number; name: string },
  origin: string,
  available_month: string[],
  quantity: number,
  quantity_unit_id: string,
  min_order: number,
  min_order_unit_id: string,
  price: number,
  currency_id: string,
  price_per_id: string,
  email: OptionType | null,
  contact_number: ReactSelectOption[],
  contact_user_id: number
) => {
  const isInvalidQuantity =
    (quantity > 0 && !quantity_unit_id) || (quantity <= 0 && quantity_unit_id)
  const isInvalidMinOrder =
    (min_order > 0 && !min_order_unit_id) ||
    (min_order <= 0 && min_order_unit_id)
  const isInvalidProduct = product.id === -1
  const isInvalidOrigin = !origin
  const isInvalidMonth = available_month.length === 0
  const isValidPrice =
    (price > 0 && (currency_id === '' || price_per_id === '')) ||
    (price <= 0 && (currency_id !== '' || price_per_id !== ''))

  const invalidContact =
    contact_user_id == -1 || email == null || contact_number?.length == 0
  return !(
    isInvalidQuantity ||
    isInvalidMinOrder ||
    isInvalidProduct ||
    isInvalidOrigin ||
    isInvalidMonth ||
    isValidPrice ||
    invalidContact
  )
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'sonner'

export const getNewsList = createAsyncThunk<
  any,
  {
    per_page: number
    page: number
    order?: string
    filter?: string
    lng?: string | string[]
  },
  { rejectValue: object }
>('getNews', async ({ per_page, page, order, filter, lng }, thunkAPI) => {
  try {
    const response = await fetch(
      `${process.env.API_URL}/news/?per_page=${per_page}&page=${page}&order=${order ?? 'desc'}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const responseData = await response.json()
    return responseData
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error })
  }
})

// GET: News Listing
export const getNewsDetail = createAsyncThunk<
  any,
  string,
  { rejectValue: object }
>('getNewsDetail', async (newsId, thunkAPI) => {
  try {
    const response = await fetch(`${process.env.API_URL}/news/${newsId}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const responseData = await response.json()
    return responseData
  } catch (error) {
    return error
  }
})

export const addNews = createAsyncThunk<FormData, any, { rejectValue: object }>(
  'news/addNews',
  async (formData: FormData, { rejectWithValue }) => {
    try {
      const response = await fetch(`${process.env.API_URL}/news`, {
        method: 'POST',
        credentials: 'include',
        body: formData,
      })
      if (!response.ok) {
        toast.error('Failed to add news')
        return rejectWithValue(await response.json())
      }
      toast.success('News added successfully')
      return response.json()
    } catch (error) {
      console.error('Error:', error)
      throw error
    }
  }
)

export const updateNews = createAsyncThunk<
  any,
  { newsId: string | number | undefined; data: FormData },
  { rejectValue: { error: any } }
>('news/updateNews', async ({ newsId, data }, thunkAPI) => {
  try {
    const response = await fetch(`${process.env.API_URL}/news/${newsId}`, {
      method: 'PATCH',
      credentials: 'include',
      body: data,
    })
    if (!response.ok) {
      toast.error('Failed to update news')
    }
    toast.success('News updated successfully')
    return response.json()
  } catch (error) {
    console.error('Error:', error)
    toast.error('Error While Updating')
    return thunkAPI.rejectWithValue({ error: 'Error while updating profile' })
  }
})

// DELETE: Delete News
export const deleteNews = async (newsId: string) => {
  try {
    const response = await fetch(`${process.env.API_URL}/news/${newsId}`, {
      method: 'DELETE',
      credentials: 'include',
    })
    if (!response.ok) {
      toast.error('Failed to delete news')
      return response.json()
    }
    toast.success('News deleted successfully')
    return response.json()
  } catch (error) {
    console.error('Error:', error)
  }
}

export const validateNewsFields = (news: any): boolean => {
  if (news) {
    if (
      news.country_id !== '' &&
      news.language_id !== '' &&
      news.criteria_id !== '' &&
      news.title !== '' &&
      news.caption !== '' &&
      news.description !== '' &&
      (news.newImages.length > 0 || news.newsCloneImg?.length > 0)
    ) {
      return true
    } else {
      return false
    }
  }
  return false
}
